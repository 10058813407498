import $ from 'jquery';
import tmpl from 'blueimp-tmpl';
import MicroModal from 'micromodal';

// Bundle prefix
const BP = '!';

function show(id) {
  const el = document.getElementById(id);
  el.classList.add('show');
  el.classList.remove('hide');
}

function hide(id) {
  const el = document.getElementById(id);
  el.classList.remove('show');
  el.classList.add('hide');
}

function isVisible(id) {
  return document.getElementById(id).classList.contains('show');
}

function startStatus(msg = '') {
  document.getElementById('status-content').innerHTML = msg;
  show('status');
}

function errorStatus(msg) {
  startStatus(msg);
}

function stopStatus() {
  hide('status');
  document.getElementById('status-content').innerHTML = '';
}

function usage() {
  showMessage(`<b>View mode</b>
  <ul class='shortcutsList'>
    <li>/ or s - Put cursor in the search field</li>
    <li>e - Edit bookmarks</li>
    <li>? - Show this help dialog</li>
  </ul>
  <b>Bundle landing page</b>
  <ul class='shortcutsList'>
    <li>Any key - Open all links</li>
  </ul>
  (Escape to close this window)`,
  'Keyboard shortcuts');
}

function load(rawMarks) {
  if (rawMarks) {
    handleJson(rawMarks);
  } else {
    startStatus("Loading...");
    $.getJSON('retrieve.php', handleJson);
  }

  function handleJson(json) {
    if (json == null || json.length == 0) {
      $("#bookmarks").html('<br>No bookmarks found');
      errorStatus("Failed to retrieve bookmarks");
      return;
    }
    stopStatus();
    // Store in global variable for referencing later
    window.data = json;
    // Check for adding an entry through bookmarklet
    if (typeof sm_add_dl != 'undefined') {
      editAll();
    } else {
      showAll();
    }
    if ($("#q")[0].value.length > 0) {
      updateSearchLinks();
    }
  }
}

function isBookmarklet(url) {
  return url.startsWith('javascript:');
}

function listenForShortcuts(event) {
  if (event.target.tagName.toLowerCase() === 'input') {
    // Ignore shortcuts when focus is in an input
    return;
  }

  if (event.key === '/' || event.key === 's') document.getElementById('q').focus();
  if (event.key === 'e') editAll();
  if (event.key === '?') usage();
}

function compareItems(a, b) {
  if (a.key < b.key) {
    return -1;
  }

  if (a.key > b.key) {
    return 1;
  }

  return 0;
}

function showAll() {
  show('searchForm');

  document.body.addEventListener('keyup', listenForShortcuts);

  // Clear this value so the row isn't highlighted on subsequent edits
  if (typeof window.keyToEdit != 'undefined') {
    window.keyToEdit = undefined;
  }

  const marks = data.marks;

  const bundleItems = [];
  const normalItems = [];

  for (let key in marks) {
    // Deal with bundles
    if (key.startsWith(BP)) {
      bundleItems.push({
        key,
        keyDataAttr: key,
        keys: formatBundleKeys(marks[key][0], marks),
        keysTitle: getBundleKeysTitle(marks[key][0], marks),
        desc: marks[key][1] === '' ? '(Missing a description)' : marks[key][1],
      });

      continue;
    }

    const title = marks[key][2];
    const linkUrl = marks[key][0];
    const searchUrl = marks[key][1];

    normalItems.push({
      key,
      title,
      linkUrl,
      isLinkBookmarklet: isBookmarklet(linkUrl),
      searchUrl,
      isSearchBookmarklet: isBookmarklet(searchUrl),
      post: (typeof marks[key][3] === 'undefined') ? '' : marks[key][3],
    });
  }

  // Handle case where default search engine key doesn't exist
  let defaultSearchEngine = '(None)';
  if (data.defaultSearchKey in marks) {
    defaultSearchEngine = truncate(marks[data.defaultSearchKey][2]);
  }

  normalItems.sort(compareItems);
  bundleItems.sort(compareItems);

  document.getElementById('bookmarks').innerHTML = tmpl('view-marks', {
    normalItems,
    bundleItems,
    defaultSearchEngine,
    loggedIn: loggedIn(),
  });

  document.getElementById('q').focus();
}

// Get the bundle keywords
function formatBundleKeys(bundleKeys, marks) {
  const bKeywords = bundleKeys.split(" ");
  let list = "";
  for (let i = 0, n = bKeywords.length; i < n; i++) {
    const bkw = bKeywords[i];
    const kwTitle = bkw in marks ? marks[bkw][2] : "No bookmark found with this keyword";
    list += bkw + ' ';
  }
  return list;
}

// Generate a title with all the names of the keywords
function getBundleKeysTitle(bundleKeys, marks) {
  const bKeywords = bundleKeys.split(" ");
  let title = '';
  for (let i = 0, n = bKeywords.length; i < n; i++) {
    const bkw = bKeywords[i];
    const kwTitle = bkw in marks ? marks[bkw][2] : "Invalid keyword, ";
    title += kwTitle;
    if (i < n - 1) {
      title += ', ';
    }
  }

  return title;
}

function showBookmarksViewOnly(obj) {
  const marks = obj.marks;

  let outMarks = '<table cellpadding="3" width="100%"><thead><tr><th width="8%">Keyword</th><th width="42%">Direct link</th><th width="42%">Search</th><th width="100">Post</th></tr></thead><tbody>';
  let outBundles = '<table cellpadding="3" width="100%"><thead><tr><th width="10%">Keyword</th><th width="50%">Description</th><th width="40%">Keywords</th></tr></thead><tbody>';
  let bundlesFound = false;

  let cnt = 0;
  for (let key in marks) {
    // Handle bundles
    if (key.startsWith(BP) || marks[key].length == 2) {
      const bundleKeys = formatBundleKeys(marks[key][0], marks);
      const bundleKeysTitle = getBundleKeysTitle(marks[key][0], marks);
      const desc = marks[key][1] === '' ? '(Missing a description)' : marks[key][1];

      outBundles += '<tr><td class="key"><b>' +key+ '</b></td>';
      outBundles += '<td><a class="bundleDesc" href="?q=' +key+ '">' +desc+ '</a></td>';
      outBundles += '<td title="' +bundleKeysTitle+'">' +bundleKeys+ '</td></tr>';
      bundlesFound = true;
      continue;
    }
    cnt++;
    const link = marks[key][0];
    const search = marks[key][1];
    const title = marks[key][2];
    const post = (typeof marks[key][3] === 'undefined') ? '' : marks[key][3];

    outMarks += '<tr><td class="key"><b>' +key+ '</b></td>';
    outMarks += (link.length == 0) ?
      '<td></td>' :
      '<td><a href="' +link+ '" title="' +link+ '">' +title+ '</a></td>';
    outMarks += (search.length == 0) ?
      '<td></td>' :
      '<td><a class="searchLink" href="' +search+ '" title="' +search+ '">' +title+ '</a></td>';
    outMarks += '<td>' +post+ '</td></tr>';
  }
  outMarks += "</tbody></table>\n";
  outBundles += "</tbody></table>\n";

  let output = '<br><b>Bookmarks found: ' +cnt+ '</b><br><br>' + outMarks;
  if (bundlesFound) {
    output += '<br><br><h3>Link Bundles</h3><br>' + outBundles;
  }

  return output;
}

function editAll(marksObj) {
  if (!loggedIn()) {
    return false;
  }

  hide('searchForm');

  const obj = (typeof marksObj === 'undefined') ? data : marksObj;
  const marks = obj.marks;
  const searchKey = obj.defaultSearchKey;
  let normalItems = [];
  let bundleItems = [];
  let searches = [];

  // Add blank rows for adding a new entry
  normalItems.push({
    key: '',
    keyDataAttr: "__new-" + new Date().getTime(),
    link: '',
    search: '',
    title: '',
    post: '',
  });

  bundleItems.push({
    key: '',
    keyDataAttr: '__newBundle-' + new Date().getTime(),
    desc: '',
    bundle: '',
  });

  for (let key in marks) {
    const item = marks[key];

    // If array has 2 entries, also treat as a bundle
    if (key.startsWith(BP) || item.length == 2) {
      bundleItems.push({
        key,
        keyDataAttr: key,
        desc: item[1],
        bundle: item[0],
      });
    } else {
      if (item[1].length > 0) {
        searches.push({
          key,
          selected: (key == searchKey) ? "selected" : "",
          truncatedSearchName: truncate(item[2]),
        });
      }

      normalItems.push({
        key,
        keyDataAttr: key,
        link: item[0],
        search: item[1],
        title: item[2],
        post: (typeof item[3] === 'undefined') ? '' : item[3],
      });
    }
  }

  searches.sort();
  normalItems.sort(compareItems);
  bundleItems.sort(compareItems);

  document.getElementById('bookmarks').innerHTML = tmpl('edit-marks', {
    searches,
    normalItems,
    bundleItems,
  });

  // Check for row being edited
  let firstInput;
  if (window.keyToEdit) {
    const matchingRow = document.querySelector(`tr[data-key='${window.keyToEdit}']`);

    if (matchingRow) {
      matchingRow.classList.toggle('editing');
      firstInput = matchingRow.querySelector('td > input');
    }
  } else {
    firstInput = document.querySelector(`#tmarks tr td input`);
  }

  if (firstInput) {
    firstInput.focus();
    firstInput.select();
  }

  return false;
}

function saveChanges() {
  const newmarks = new Object();
  newmarks.marks = {};

  // Clear error class on all rows
  $("tr").removeClass('error');

  const tmarks = document.getElementById("tmarks");

  // Start at 1 to skip table header
  for (let i = 1; i < tmarks.rows.length; i++) {
    const row = tmarks.rows[i];
    const kw = row.cells[0].firstChild.value.replace(/\s/g, '').toLowerCase();
    row.cells[0].firstChild.value = kw;
    const title = row.cells[1].firstChild.value;
    let dl = row.cells[2].firstChild.value;
    const sl = row.cells[3].firstChild.value.replace(/SHORTMARKS/g, '%s');
    const post = row.cells[4].firstChild.value;
    const id = $("#tmarks tr").eq(i).attr('data-key');

    // Skip row if all fields are empty
    if (kw.length == 0 && title.length == 0 && dl.length == 0
      && sl.length == 0 && post.length == 0) {
      continue;
    }

    let msg = ''

    if (kw.length == 0) {
      setErrorClass(id);
      msg += addError("Missing keyword", "The keyword field can't be empty.  Please enter a keyword and save again.");
    }
    if (kw.startsWith(BP)) { // Check for bundle prefix
      setErrorClass(id);
      msg += addError("Reserved character in keyword", "The " +BP+ " character is reserved for link bundles. Please use a different character in keyword <em>" +kw+ "</em>.");
    }
    if (kw in newmarks.marks) {
      setErrorClass(id);
      msg += addError("Duplicate keywords", "Two entries have the same keyword (" + kw + ")\nPlease change one of them and save again");
    }
    if (title.length == 0) {
      setErrorClass(id);
      msg += addError("Missing name", "The entry with keyword <em>" + kw + "</em> is missing a name.");
    }
    if (dl.length == 0 && sl.length == 0) {
      setErrorClass(id);
      setErrorClass(id);
      msg += addError("Missing links", "Please add a link in the direct link or search link fields (or both)");
    }

    if (msg.length != 0) {
      showErrorMessage(msg);
      return;
    }

    // Replace all double quotes with singles in bookmarklets
    if (dl.startsWith("javascript:")) {
      dl = dl.replace(/"/g, "'");
    }

    newmarks.marks[kw] = new Array(checkUrl(dl), checkUrl(sl), title);
    // Add post info if it exists
    if (post.length > 0) {
      newmarks.marks[kw].push(post);
    }
  }

  const tbundles = document.getElementById("tbundles");
  // Start at 1 to skip table header
  for (let i = 1; i < tbundles.rows.length; i++) {
    const row = tbundles.rows[i];
    let bkw = row.cells[0].firstChild.value.replace(/\s/g, '').toLowerCase();
    const desc = row.cells[1].firstChild.value;
    // Replace all multiple spaces with a single one
    let bundle = row.cells[2].firstChild.value.replace(/\s{2,}/g, ' ');
    bundle = $.trim(bundle); // Remove leading and trailing whitespace
    const bunId = $("#tbundles tr").eq(i).attr('data-key');

    // Skip row if all fields are empty
    if (bkw.length == 0 && desc.length == 0 && bundle.length == 0) {
      continue;
    }

    let bmsg = '';

    if (bkw.length === 0) {
      setErrorClass(bunId);
      bmsg += addError("Missing keyword", "The bundle keyword can't be blank.  Please enter a keyword (with a " +BP+ " in front) and save again.");
    }

    if (desc === '') {
      setErrorClass(bunId);
      bmsg += addError("Missing description", "Please add a description for the <em>" + bkw + "</em> bundle.");
    }

    // Don't allow the use of the bundle prefix alone
    if (bkw == BP) {
      setErrorClass(bunId);
      bmsg += addError("Reserved keyword", BP + " is reserved for displaying a list of all your bundles.  Please choose another keyword for the <em>" + desc + "</em> bundle.");
    }

    // Add the bundle prefix if it's not already there
    if (!bkw.startsWith(BP)) {
      bkw = BP + bkw;
    }

    if (bkw in newmarks.marks) {
      setErrorClass(bunId);
      bmsg += addError("Duplicate keywords", "Two bundles have the same keyword (" + bkw + ")\nPlease change one of them and save again.");
    }
    if (bundle.length == 0) {
      setErrorClass(bunId);
      bmsg += addError("Empty keyword list", "Please add a list of keywords.");
    } else {
      // Make sure the bundle keywords point to valid bookmarks
      const bkeys = bundle.split(" ");
      for (let j = 0, n1 = bkeys.length; j < n1; j++) {
        if (!(bkeys[j] in newmarks.marks)) {
          setErrorClass(bunId);
          bmsg += addError("Keyword doesn't exist", `The '${bkeys[j]}' keyword doesn't exist (it's specified by the <em>${bkw}</em> bundle)`);
        }
      }
    }

    if (bmsg.length > 0) {
      showErrorMessage(bmsg);
      return;
    }

    newmarks.marks[bkw] = new Array(bundle, desc);
  }

  newmarks.defaultSearchKey = document.getElementById('defaultSearch').value;

  if (!newmarks.marks[newmarks.defaultSearchKey]) {
    showErrorMessage(`The default search engine you've selected doesn't exist: ${newmarks.defaultSearchKey}`);
    return;
  }

  uploadToServer(newmarks);
}

function uploadToServer(newmarks) {
  startStatus("Saving...");
  $.ajax({
    url: "save.php",
    data: JSON.stringify(newmarks),
    success: receiveData,
    contentType: "application/json; charset=utf-8",
    dataType: "json", // How to receive data from server
    type: "POST",
  });
}

function receiveData(responseData, responseStatus, jqXHR) {
  stopStatus();
  window.data = responseData;
  showAll();
}

function addRow() {
  const newKey = "__new-" + new Date().getTime();
  // Check for new entry fields
  const t = (typeof sm_add_title == 'undefined') ? '' : sm_add_title;
  const dl = (typeof sm_add_dl == 'undefined') ? '' : sm_add_dl;
  const sl = (typeof sm_add_sl == 'undefined') ? '' : sm_add_sl;
  $("#tmarks").prepend(getEditRow('', newKey, t, dl, sl, ''));
  $("tr td input").focus(function () { $(this).removeClass('error'); });
  $(`tr[data-key='${newKey}'] td:first input`).focus().select();
  return false;
}

function addBundleRow(setFocus) {
  const newKey = "__newBundle-" + new Date().getTime();
  $("#tbundles").prepend(getBundleEditRow('', newKey, '', ''));
  $("tr td input").focus(function () { $(this).removeClass('error'); });
  if (setFocus) {
    $(`tr[data-key='${newKey}'] td:first input`).focus();
  }
  return false;
}

function delRow(event) {
  event.parentElement.parentElement.remove();
  return false;
}

function updateSearchLinks() {
  let query = $("#q").val();
  if (query.length == 0) {
    showMessage("Oops, there's no search query.  I'll add one, then you can click on any link in the <em>Search link</em> column to search for the phrase on that site.  Pro tip: Middle click the link to open the site in the background.");
    query = "weather";
    $("#q").val(query);
  }
  // Reload bookmarks to get the %s back in the URL
  showAll();
  $("a[href].searchLink").attr('href', function() {
    try {
      return new String(this).replace(/%s/, query);
    } catch(err) {
      // IE workaround
      return $(this.outerHTML).attr('href').replace(/%s/, query);
    }
  });
  $("a[href].searchLink").append("&rightarrow; search for &quot;" + query + "&quot;");
  return false;
}

function truncate(str, suffix) {
  if (typeof suffix == "undefined") suffix = "&hellip;";
  if (typeof str == "undefined") return '';
  return str.length > 50 ? str.substr(0, 50) + suffix : str;
}

function getEditRow(key, dataKey, title, link, search, post) {
  return `<tr data-key="${dataKey}">
    <td><input type="text" value="${key}"></td>
    <td><input type="text" maxlength="100" value="${title}"></td>
    <td><input type="text" value="${link}"></td>
    <td><input type="text" value="${search}"></td>
    <td><input type="text" value="${post}"></td>
    <td><button class="button small white" onClick="delRow(this)" title="Delete this row">Delete</button></td>
    </tr>`;
}

function getBundleEditRow(key, dataKey, desc, bundle) {
  return `<tr data-key="${dataKey}"><td><input type="text" value="${key}"></td>
    <td><input type="text" maxlength="100" value="${desc}"></td>
    <td><input type="text" value="${bundle}"></td>
    <td><button class="button small white" onClick="delRow(this)" title="Delete this row">Delete</button></td></tr>`
}

function editRow(key) {
  if (!loggedIn()) {
    showMessage("You can edit once you've created your own account.");
    return;
  }
  window.keyToEdit = key;
  editAll();
}

function getSearchProviderUrl() {
  return 'https://' + window.location.hostname + '/search.php';
}

function addSearchProvider() {
  if (window.external && ("AddSearchProvider" in window.external)) {
    window.external.AddSearchProvider(getSearchProviderUrl());
  } else {
    // No search engine support (IE 6, Opera, etc).
    showMessage("No automatic search engine support found");
  }
}

// Returns incorrect values in Chrome and IE
function checkSearchProvider() {
  const isInstalled =
    window.external.IsSearchProviderInstalled(getSearchProviderUrl());
  let msg = '';
  let style = null;
  if (isInstalled == 1) {
    msg = "Shortmarks is a search engine, but not the default";
    style = {'color':'green','font-weight':'normal'};
  } else if (isInstalled == 2) {
    msg = "Shortmarks is the default search engine.";
    style = {'color':'green','font-weight':'bold'};
  } else {
    msg = "Shortmarks has not been added as a search engine.";
    style = {'color':'red','font-weight':'normal'};
  }
  if (style != null) {
    $("#searchProviderCheck").css(style);
  }
  if (msg.length > 0) {
    $("#searchProviderCheck").html(msg);
  }
}

function toggleTools() {
  if (!loggedIn()) {
    return false;
  }

  if (isVisible('tools')) {
    hide('tools');
    document.getElementById('toolsLink').innerText = 'Tools';
    show('searchForm');
    show('bookmarks');
  } else {
    hide('searchForm');
    hide('bookmarks');
    document.getElementById('toolsLink').innerText = 'Bookmarks';
    document.getElementById('tools').innerHTML = tmpl('tools-tmpl', {});
    show('tools');
    initRestore();
  }
  return false;
}

function getTimestamps() {
  startStatus("Loading saved copies...");
  $.getJSON('timestamps.php', function(timestamps) {
    stopStatus();
    if (timestamps == null || timestamps.length == 0) {
      $("#restoreBox").html('No timestamps found');
      return;
    }
    let list = '<select id="timestampsSelect">';
    const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
    for (let i = 0, n = timestamps.length; i < n; i++) {
      const ts = timestamps[i];
      // Convert time to browser's local timezone
      const dt = new Date(ts * 1000 - tzOffset).toString();
      list += '<option value="' +ts+ '">' +dt+ '</option>';
    }
    list += '</select> <input type="button" id="previewTimestamp" value="Show">';
    $("#restoreBox").html(list + '<br><br><div id="previewMarks"></div><div id="previewMarksBtns"></div>');
    $("#previewTimestamp").on("click", previewRestore);
  });
}

let restoreMarks;
function previewRestore() {
  const ts = $("#timestampsSelect option:selected").val();
  startStatus("Retrieving bookmarks...");
  $.getJSON('marksByTimestamp.php?timestamp=' + ts, function(restoreData) {
    stopStatus();
    if (restoreData == null || restoreData.length == 0) {
      clearRestorePreview();
      $("#previewMarks").html('<br>No bookmarks were found for that date');
      return;
    }
    restoreMarks = restoreData;
    $("#previewMarks").html(showBookmarksViewOnly(restoreData) + '<br><br>');
    $("#previewMarksBtns").html('<br><input type="button" value="Preview restore" onClick="confirmRestore()"><input type="button" value="Clear" id="clearRestorePreview"><br>');
    $("#clearRestorePreview").on("click", clearRestorePreview);
  });
}

function confirmRestore() {
  toggleTools();
  editAll(restoreMarks);
}

function clearRestorePreview() {
  $("#previewMarks").html('');
  $("#previewMarksBtns").html('');
}

function initRestore() {
  $("#restoreBox").html('<input type="button" onClick="getTimestamps();" value="Retrieve list"><br>');
}

function showImport() {
  const content = '<b>Copy the contents of the bookmarks file into the text box below. (HTML, JSON, SQL and search.ini formats will be auto-detected)</b><br>\
  <textarea id="marksImport" rows="20"></textarea><br><br>\
  <input type="button" value="Preview import" onClick="previewImport()">\
  <input type="button" value="Cancel" onClick="hideImport()"><br>';
  // Don't clear textarea if it already has text in it
  if ($("#marksImport").length == 0) {
    $("#importBox").html(content);
  }

  document.getElementById('marksImport').focus();
}

function hideImport() {
  document.getElementById('importBox').innerHTML = '';
}

function previewImport() {
  let marksToImport = getImportedMarks();
  if (marksToImport == null) {
    document.getElementById('importBox').innerHTML = '<h2>Import results</h2>No bookmarks found<br><br><input type="button" value="Try again" onClick="showImport()"><br>';
    return;
  }

  const importType = $('input:radio[name=importType]:checked').val();

  if (importType != 'overwrite') {
    // Deep copy from http://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-clone-a-javascript-object
    const newData = $.extend(true, {}, data);
    const imported = marksToImport.marks;
    for (let key in imported) {
      // Skip over identical keys if only merging
      if (key in newData.marks && importType == 'merge') {
        continue;
      } else {
        newData.marks[key] = marksToImport.marks[key];
      }
    }

    marksToImport = $.extend(true, {}, newData);
  }

  toggleTools();
  editAll(marksToImport);
}

function importHTML(html) {
  // Get generate keywords checkbox value
  const genKeywords = $("input:checkbox[name=genKeywords]").attr('checked');

  newMarks = new Object();
  newMarks.marks = Array();
  const lines = html.split(/[\n\r]+/);
  const prefix = "<DT><A";
  const regex = /<DT><A (.*)>(.*)<\/A>/;
  for (let i = 0, n = lines.length; i < n; i++) {
    line = lines[i].replace(/^\s+/g, '');;
    // Skip line if it isn't a URL entry
    if (line.substring(0, prefix.length) !== prefix) { continue; }
    const fields = regex.exec(line);
    if (fields == null || fields.length == 0) { continue; }
    const attrs = fields[1].split(' ');
    const tuples = new Array();
    for (let j = 0, n2 = attrs.length; j < n2; j++) {
      const attr = attrs[j].replace(/\s+/g, '');
      if (attr.indexOf('="') == -1) { continue; }
      const firstEquals = attr.indexOf('="');
      const key = attr.substring(0, firstEquals);
      // Skip over = and " (hence +2)
      const val = attr.substring(firstEquals+2, attr.lastIndexOf('"'));
      tuples[key] = val;
    }
    const title = fields[2];
    const url = tuples["HREF"];
    if (url.indexOf('place:') != -1) { continue; } // Skip place bookmarks
    let keyword = '';
    if ("SHORTCUTURL" in tuples) {
      keyword = sanitizeKeyword(tuples["SHORTCUTURL"], newMarks.marks);
    } else if (genKeywords) {
      // No keyword found and generate keywords checked
      keyword = sanitizeKeyword(generateKeyword(url), newMarks.marks);
    }

    if (keyword.length == 0) {
      continue;
    }

    // keyword => direct, search, title
    let entry = Array();
    if (url.indexOf("%s") == -1) {
      entry[0] = url;
      entry[1] = '';
    } else {
      // Create a direct link for custom search entries
      entry[0] = getBaseUrl(url);
      entry[1] = url;
    }

    entry[2] = title;

    if ("POST_DATA" in tuples) {
      entry[3] = tuples["POST_DATA"];
    }

    newMarks.marks[keyword] = entry;
  }

  return newMarks;
}

// For Chrome search engines
function importSQL(sql) {
  newMarks = new Object();
  newMarks.marks = Array();
  sql = sql.replace(/{searchTerms}/g, '%s');
  sql = sql.replace(/{inputEncoding}/g, 'UTF-8');
  const lines = sql.split(/[\n\r]+/);
  for (let i = 0; i < lines.length; i++) {
    line = lines[i];
    const idxValues = line.indexOf("VALUES");
    if (idxValues == -1) { continue; }
    const fields = line.substr(idxValues).split(",")
    if (fields == null || fields.length == 0) { continue; }
    url = fields[4].replace(/'/g, '');
    keyword = sanitizeKeyword(fields[2].replace(/'/g, ''), newMarks.marks);
    title = fields[1].replace(/'/g, '');
    if (url.indexOf('http') == -1) { continue; }
    // keyword => direct, search, title
    const entry = Array();
    if (url.indexOf("%s") == -1) {
      entry[0] = url;
      entry[1] = '';
    } else {
      entry[0] = getBaseUrl(url);
      entry[1] = url;
    }
    entry[2] = title;
    newMarks.marks[keyword] = entry;
  }

  return newMarks;
}

// For Opera search engines (search.ini)
function importOperaSearch(contents) {
  newMarks = new Object();
  newMarks.marks = Array();
  const genKeywords = $("input:checkbox[name=genKeywords]").attr('checked');
  let lines = contents.split(/[\n\r]+/);
  let tuples = new Array();
  let reachedSearchEngines = false;
  let defSearchId;
  for (let i = 0; i < lines.length; i++) {
    line = lines[i];
    // Encounter [Search Engine] entry or end of file
    if (line.indexOf("[Search Engine") != -1 || i == lines.length-1) {
      if (reachedSearchEngines) {
        let kw = tuples["Key"];
        const uniqueId = tuples["UNIQUEID"];
        const title = tuples["Name"];
        const url = tuples["URL"];
        const post = tuples["Is post"];
        const query = tuples["Query"];
        const deleted = tuples["Deleted"];

        // Clear values for next search engine
        tuples = new Array();

        // Skip deleted entries or those with blank URLs
        if (deleted == "1"
          || typeof url === 'undefined' || url.length == 0) {
          continue;
        }

        if (generateKeyword &&
          (typeof kw === 'undefined' || kw.length == 0) &&
          (!typeof url === 'undefined' && url.length > 0)) {
          kw = generateKeyword(url);
        }
        kw = sanitizeKeyword(kw.replace(/'/g, ''), newMarks.marks);

        // keyword => direct, search, title, post
        let entry = Array();
        entry[0] = getBaseUrl(url);
        entry[1] = url;
        entry[2] = title;
        if (post == "1" && query.length > 0) {
          entry[3] = query;
        }
        newMarks.marks[kw] = entry;

        // Set default search
        if (uniqueId == defSearchId) {
          newMarks.defaultSearchKey = kw;
        }
      } else {
        reachedSearchEngines = true;
      }
    } else if (line.indexOf("Default Search") != -1) {
      defSearchId = line.split("=")[1];
    } else if (reachedSearchEngines && line.indexOf("=") != -1) {
      const firstEquals = line.indexOf('=');
      const k = line.substring(0, firstEquals);
      const v = line.substring(firstEquals + 1);
      tuples[k] = v;
    }
  }

  return newMarks;
}

// Convert the text in the bookmarks field into a bookmarks data structure
function getImportedMarks() {
  const importText = $("textarea#marksImport").val();
  if (importText == null || importText.length == 0) {
    return null;
  }
  let newMarks;
  try {
    newMarks = JSON.parse(importText);
    return newMarks;
  } catch (e) {
    // Ignore exception
  }

  if (importText.startsWith('<!DOCTYPE NETSCAPE-Bookmark-file-1>')) {
    return importHTML(importText);
  }

  if (importText.startsWith('Opera Preferences version ')) {
    return importOperaSearch(importText);
  }

  if (importText.indexOf("INSERT INTO") != -1) {
    return importSQL(importText);
  }

  return null;
}

function exportAsJSON() {
  const output = JSON.stringify(data);
  $("#exportBox").html('<b>Copy the text below and save it to a .json file.</b><br><textarea id="jsonExportTextarea" rows="20">' + output + '</textarea><br><input type="button" value="Close" onClick="hideExport()"><br>');
  document.getElementById('jsonExportTextarea').select();
}

function exportAsHTML() {
  const time = new Date().getTime();
  let output = "<!DOCTYPE NETSCAPE-Bookmark-file-1>\n" +
  "<!-- This is an automatically generated file.\n" +
  "   It will be read and overwritten.\n" +
  "   DO NOT EDIT! -->\n" +
  '<META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=UTF-8">' + "\n" +
  "<TITLE>Bookmarks</TITLE>\n" +
  "<H1>Bookmarks</H1>\n" +
  "<DL><p>\n" +
  '    <DT><H3 ADD_DATE="' +time+ '" LAST_MODIFIED="' +time+ '">Shortmarks</H3>' + "\n" +
  "    <DL><p>\n";

  const marks = data.marks;
  for (let key in marks) {
    // Skip bundles
    if (key.startsWith(BP)) { continue; }
    const link = marks[key][0];
    const search = marks[key][1];
    const title = marks[key][2];
    const post = (typeof marks[key][3] === 'undefined') ? '' : marks[key][3];

    // Direct link
    if (link.length != 0) {
      output += '\t\t<DT><A HREF="' +link+ '" ADD_DATE="' +time+ '"';
      // Add SHORTCUTURL if there isn't a search entry
      if (search.length == 0) {
        output += ' SHORTCUTURL="' +key+ '"';
      }
      output += '>' +title+ '</A>' + "\n";
    }

    // Search link
    if (search.length != 0) {
      output += '\t\t<DT><A HREF="' +search+ '" ADD_DATE="' +time+ '" SHORTCUTURL="' +key+ '"';
      if (post.length != 0) {
        output += ' POST_DATA="' +post+ '"';
      }
      output += '>' +title+ ' search</A>' + "\n";
    }
  }

  output += " </DL><p>\n" +
  "</DL><p>\n";
  $("#exportBox").html('<b>Copy the text below and save it to an .html file.</b><br><textarea id="htmlExportTextarea" rows="20">' + output + '</textarea><br><input type="button" value="Close" onClick="hideExport()"><br>');
  document.getElementById('htmlExportTextarea').select();
}

function hideExport() {
  $("#exportBox").html('');
}

function getBaseUrl(str) {
  return getDomain(str, true);
}

function generateKeyword(str) {
  return getDomain(str, false);
}

function getDomain(str, incProtocol) {
  const delimiter = "://";
  let postDelim = 0;
  let protocol = '';
  if (str.indexOf(delimiter) != -1) {
    postDelim = str.indexOf(delimiter) + delimiter.length;
    protocol = str.substring(0, postDelim);
  }
  let url = str.substring(postDelim);
  if (url.indexOf('/') != -1) {
    url = url.substring(0, url.indexOf('/'));
  }
  if (url.startsWith("www.")) {
    url = url.substring("www.".length);
  }

  return incProtocol ? protocol + url : url;
}

// Make sure imported keyword is valid within shortmarks
function sanitizeKeyword(kw, marks) {
  kw = kw.replace(/\s+/g, '_').replace(BP, 'a');
  let i = 1;
  let newKw = kw;
  // Add a number on the end until it's unique
  while (newKw in marks) {
    newKw = kw + i++;
  }
  return newKw;
}

// Add http if there's no protocol
function checkUrl(url) {
  if (url.startsWith('javascript:') || url.startsWith('mailto:')) {
    return url;
  }
  if (url.length != 0 && url.indexOf('://') == -1) {
    url = 'http://' + url;
  }
  return url;
}

function showMessage(msg, title="Message") {
  $('#modal-1-content').html(msg);
  $('#modal-1-title').html(title);
  MicroModal.show('modal-1', { disableScroll: true });
}

function showErrorMessage(msg, title="Error") {
  showMessage(`<div class='dialogError'>${msg}</div>`, title);
}

function addError(title, msg) {
  return "<b>" +title+ "</b> - " + msg + "<br>";
}

function setErrorClass(key) {
  const tr = document.querySelector(`tr[data-key='${key}']`);
  if (tr) {
    tr.classList.add('error');
  }
}

function loggedIn() {
  // l__ is the id of the login link. If it's 0 length the user is logged in
  return $("#l__").length == 0;
}

function filterTable(tableId, filterId, columnIndex, elementFunction, matchesFunction) {
  const table = document.getElementById(tableId);
  const filter = document.getElementById(filterId).value.toLowerCase();
  const rows = table.getElementsByTagName('tr');

  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    let td = row.getElementsByTagName('td')[columnIndex];

    if (!td) {
      continue;
    }

    if (matchesFunction(elementFunction(td).toLowerCase(), filter)) {
      row.style.display = "";
    } else {
      row.style.display = "none";
    }
  }
}

function filterByKey() {
  filterTable('marksTable', 'keyFilter', 0, td => td.innerText, (a, b) => a.startsWith(b));
}

function filterByTitle() {
  filterTable('marksTable', 'titleFilter', 1, td => td.innerText, (a, b) => a.indexOf(b) > -1);
}

function filterEditByKey() {
  filterTable('tmarks', 'keyFilter', 0, td => td.firstElementChild.value, (a, b) => a.startsWith(b));
}

function filterEditByTitle() {
  filterTable('tmarks', 'titleFilter', 1, td => td.firstElementChild.value, (a, b) => a.indexOf(b) > -1);
}

window.addEventListener('DOMContentLoaded', (event) => {
  // Globals / Exported functions
  window.$ = $;
  window.data;

  // View page
  window.updateSearchLinks = updateSearchLinks;
  window.editAll = editAll;
  // window.filterByKey = filterByKey;
  // window.filterByTitle = filterByTitle;

  // Edit page
  window.showAll = showAll;
  window.addRow = addRow;
  window.editRow = editRow;
  window.delRow = delRow;
  window.addBundleRow = addBundleRow;
  window.saveChanges = saveChanges;
  window.filterEditByKey = filterEditByKey;
  window.filterEditByTitle = filterEditByTitle;

  // Help pages
  window.checkSearchProvider = checkSearchProvider;
  window.addSearchProvider = addSearchProvider;

  // Tools
  window.getTimestamps = getTimestamps;
  window.confirmRestore = confirmRestore;
  window.showImport = showImport;
  window.previewImport = previewImport;
  window.exportAsHTML = exportAsHTML;
  window.exportAsJSON = exportAsJSON;
  window.hideExport = hideExport;

  // Add a startsWith method to the String object
  String.prototype.startsWith = function(prefix) {
    return this.substring(0, prefix.length) === prefix;
  }

  // Register AJAX handler
  $(document).ajaxError(function(event, request, settings) {
    errorStatus("Oops, an error occurred.  Please try the operation again.");
  });

  $("#toolsLink").on("click", toggleTools);

  const loadData = document.getElementById('load-data-flag');

  // Only load data on the shortmarks data page
  if (loadData && loadData.getAttribute('data-load-data') === 'true') {
    load();
  }
});
